.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 120px 40px 100px;
  }
  
  .service-card {
    background-color: #000;
    color: #ffffff;
    width: 23%;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  
    @media (max-width: 1250px) and (min-width: 769px) {
      width: 48%; /* 2 tarjetas por fila */
    }
  
    @media (max-width: 768px) {
      width: 100%; /* 1 tarjeta por fila en pantallas más pequeñas */
    }
  }
  
  .image-container {
    position: relative;
    height: 250px;
    flex-shrink: 0;
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      color: transparent;
    }
  }
  
  .text-container {
    padding: 40px 20px 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h3 {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: left;
      margin-left: 10px;
      flex-shrink: 0;
      li {
        text-align: left;
        margin-bottom: 15px;
        margin-left: 20px;
      }
    }
  }
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 10px;
    flex-shrink: 0;
  }
  
  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    li {
      text-align: left;
      margin-bottom: 15px;
      margin-left: 20px;
    }
}