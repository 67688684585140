.detalleFooter{
    background-color: #000;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8vh;
    border-top: 1px solid #ffffff88; /* Añade esta línea para el borde superior blanco */


    p {
        font-size: 1rem;
        margin: auto 0;
        color: #fff;
        a {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        &:hover {
            color: gray;
            transition: 0.2s;
        }
        }
    }

    @media (max-width: 900px) {
        p {font-size: 0.9rem;}
    }

    @media (max-width: 750px) {
        p {
        width: 90%;
        text-align: center;
        font-size: 0.8rem;
        }
    }

    @media (max-width: 500px) {
        p {
        font-size: 0.7rem;
        }
    }
}