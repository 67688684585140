$navbar-bg-color: #ffffff;
$navbar-text-color: #000;
$transparent: transparent;
$white: #fff;
$black: #000;

.navbar-container1 {
  transition: all 0.5s ease;
  background-color: $navbar-bg-color;
  box-shadow: 0px 0px 10px rgba(81, 81, 81, 0.474);
  padding: 5px 30px;

  @media (max-width: 600px) {
    padding: 5px 35px;
  }
}

.navbar-container2 {
  transition: all 1s ease;
  background-color: $transparent;
  padding: 5px 30px;

  @media (max-width: 600px) {
    padding: 5px 35px;
  }
}

.bi-x {
  color: $black !important;
}

.navbar-container1,
.navbar-container2 {
  position: fixed;
  z-index: 10;
  width: 100%;

  .navbar-bg,
  .navbar-nobg {
    width: 96%;
    justify-content: space-between;
    display: flex;
    margin: auto;
    align-items: center;

    .left {
      z-index: 1;

      .logo {
        transition: all 1s ease;
        width: 100%;
        height: 60px;
        margin: 0;

        // @media (max-width: 600px) {
        //   transition: all 1s ease;
        //   width: 100%;
        //   height: 50px;
        //   margin: 0;
        // }

        .logo_time {
          transition: all 1s ease;
          width: 100%;
          height: 100%;
          margin: 0;
          object-fit: cover;
        }
      }
    }

    .right {
      .bi {
        transition: color 0.3s ease;
      }
  
      .white-icon {
        color: $white;
      }
  
      .black-icon {
        color: $black;
      }

      &.dark-theme {
        .nav-link {
          color: $black;
          &::before {
            background-color: $black;
          }
        }
      }

      &.light-theme {
        .nav-link {
          color: $white;
          &::before {
            background-color: $white;
          }
        }
      }

      .links {
        display: flex;
        align-items: center;
        gap: 40px;

        @media (max-width: 1000px) {
          display: none;
        }

        .nav-link {
          position: relative;
          text-decoration: none;
          font-size: 1rem;
          font-weight: 400;
          display: inline-block;
          transition: color 0.3s ease;

          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            transform: scaleX(0);
            transform-origin: bottom right;
            transition: transform 0.5s ease;
          }

          &:hover::before {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
        }
      }

      i {
        font-size: 40px;
        display: none;
        z-index: 10;

        @media (max-width: 1000px) {
          display: block;
          z-index: 10;
        }
      }
    }

    .bg-div {
      background-color: #fdfaf8;
      position: absolute;
      opacity: 0;
      box-shadow: 0px 0px 100px rgba(81, 81, 81, 0.358);
      width: 50%;
      height: 100vh;
      top: 0;
      right: 0;
      z-index: -1;
      transform: translateX(100%);
      transition: all 0.6s ease;
      transition: all 1s ease;

      @media (max-width: 600px) {
        width: 60%;
      }

      &.active {
        display: flex;
        transform: translateX(0);
        opacity: 1;
        transition: all 0.6s ease;

        @media (min-width: 1000px) {
          opacity: 0;
          display: none;
        }
      }
    }

    .links-active {
      display: none;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      justify-content: center;
      align-items: end;
      padding: 0 100px;
      gap: 40px;

      @media (max-width: 600px) {
        padding: 0 50px;
      }

      a {
        color: $black;
        text-decoration: none;
        font-size: 1.2rem;
        text-align: start;
        font-weight: 400;
      }
    }
  }
}