.contenedor-principal-slider{

  // Flechas de navegación
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff;

    @media (max-width: 768px) {
      width: 5px; 
      height: 5px;
      transform: scale(0.4); // Reduce el tamaño de las flechas al 70% en dispositivos móviles
    }
  }

  // Puntos de paginación
  .swiper-pagination-bullet {
    background-color: #fff;

    &.swiper-pagination-bullet-active {
      background-color: #fff;
    }
  }

  .slider1{
      background-image: url("../../media/1exterior.webp");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
      padding: 150px 100px;
      width: 100vw;
      height: 100vh;

      @media(max-width: 1350px) {
          padding: 110px 100px;
      }
      @media(max-width: 800px) {
          padding: 100px 20px;
      }
  }

  .slider2{
    background-image: url("../../media/2interior.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    padding: 150px 100px;
    width: 100vw;
    height: 100vh;

    @media(max-width: 1350px) {
        padding: 110px 100px;
    }
    @media(max-width: 800px) {
        padding: 100px 20px;
    }
  }

  .slider3{
    background-image: url("../../media/3interior.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    padding: 150px 100px;
    width: 100vw;
    height: 100vh;

    @media(max-width: 1350px) {
        padding: 110px 100px;
    }
    @media(max-width: 800px) {
        padding: 100px 20px;
    }
  }

  .contenido{
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 20px;
      margin: auto;
      h2{
        font-size: 2.4rem;
        padding: 0 0 20px 0;
        line-height: 1.2;
        font-weight: 700;
        max-width: 80%;
      }
      p{
        font-size: 1.2rem;
        padding: 0 0 40px 0;
        line-height: 1.5;
        font-weight: 300;
        max-width: 70%;
      }

      .cta-text {
        padding: 10px 20px;
        border: 2px solid #fff;
        font-size: 0.9rem;
        font-weight: 500;
        color: #fff;
        background-color: transparent;
        border-radius: 30px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: all 0.3s ease;
      }
    
      @media (max-width: 768px) {
        h2 {
          font-size: 1.9rem;
          padding: 0 0 15px 0;
        }
    
        p {
          font-size: 1rem;
          padding: 0 0 15px 0;
        }
    
        .cta-text {
          font-size: 0.8rem;
          padding: 8px 16px;
        }
      }
    
      @media (max-width: 480px) {
        h2 {
          font-size: 1.3rem;
          padding: 0 0 10px 0;
          max-width: 90%;
        }
    
        p {
          font-size: 0.9rem;
          padding: 0 0 10px 0;
          max-width: 85%;
          text-align: justify;
        }
    
        .cta-text {
          font-size: 0.7rem;
          padding: 6px 12px;
        }
      }
  }
}