.btn-flotante {
    position: relative;
    .boton-wsp {
        position: fixed;
        width: 70px;
        height: 70px;
        line-height: 55px;
        bottom: 50px;
        right: 50px;
        background: #0df053;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 2.5rem;
        text-decoration: none;
        z-index: 100;
        display: flex;
        align-items: center;
        justify-content: center;

        @media(max-width: 900px) {
            bottom: 30px;
            right: 30px;
            width: 60px;
            height: 60px;
            font-size: 2.4rem;
            padding: 0 5px;
        }

        &:hover {
            background: #00ff37;
        }

        span {
            margin-left: 10px;
            font-size: 15px;

            display: flex;
            align-items: center;
            justify-content: center;

            @media(max-width: 900px) {
                font-size: 13px;
            }

        }
    }
}