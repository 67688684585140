.contenedor-principal-Trabajos {
    background-color: rgb(209, 209, 209);
    text-align: center;
    padding: 120px 0 110px 0;

    .elSlider {
        justify-content: center;
        margin: auto;
        width: 90%;
    }

    .trabajos {
        padding: 0px 20px;
        margin: auto;
        display: flex;
        justify-content: center;

        .trabajo {
            padding: 0px;
            box-shadow: 0px 0px 10px #1111111a;

            .imagen {
                width: 100%;
                padding-top: calc(1600 / 1200 * 100%); /* Mantiene la proporción 1200x1600 */
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover; /* Asegura que la imagen cubra el contenedor sin distorsión */
                    border-radius: 0px;
                }
            }
        }
    }
}