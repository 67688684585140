.error {
  color: rgb(255, 103, 103);
}

.exito {
  color: rgb(108, 224, 108);
}

.contact-section {
    display: flex;
    flex-wrap: wrap;
    background-color: #000;
    color: #FFFFFF;
    padding: 30px 50px 30px;
    justify-content: space-around;
  
    @media (min-width: 1200px) {
      justify-content: space-around;
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 40px 30px 20px;
    }
  }
  
  .contact-left,
  .contact-right {
    //width: 50%;
  
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  
  .contact-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  
    h4 {
      font-size: 1.2rem;
      font-weight: 300;
      margin-bottom: 10px;
    }
  
    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 20px;
    }
  
    p {
      font-size: 0.9rem;
      font-weight: 300;
      margin-bottom: 10px;
    }
  
    .social-media {
      display: flex;
      gap: 20px;
  
      .social-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        a {
          font-size: 2rem;
          color: #FFFFFF;
          transition: color 0.3s;
  
          &:hover {
            color: #BBBBBB;
          }
        }
  
        span {
          font-size: 0.8rem;
          font-weight: 300;
          margin-top: 5px;
        }
      }
    }
  }
  
  .contact-right {
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .social-media {
      display: flex;
      align-items: center;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      gap: 30px;

      .social-icon {
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        a {
          font-size: 4rem;
          color: #FFFFFF;
          transition: color 0.3s;

          &:hover {
            color: #BBBBBB;
          }
        }

        @keyframes colorChangeInstagram {
          0% {
            color: #ea8bc1; /* Color inicial para Instagram */
          }
          50% {
            color: #b90f71; /* Color intermedio para Instagram */
          }
          100% {
            color: #ea8bc1; /* Vuelve al color inicial para Instagram */
          }
        }

        @keyframes colorChangeWhatsApp {
          0% {
            color: #7af0aa; /* Color inicial para WhatsApp */
          }
          50% {
            color: #038d3a; /* Color intermedio para WhatsApp */
          }
          100% {
            color: #7af0aa; /* Vuelve al color inicial para WhatsApp */
          }
        }

        @keyframes colorChangeGmail {
          0% {
            color: #e08880; /* Color inicial para Gmail */
          }
          50% {
            color: #d81e12; /* Color intermedio para Gmail */
          }
          100% {
            color: #e08880; /* Vuelve al color inicial para Gmail */
          }
        }

        .ani-ig {
          animation: colorChangeInstagram 5s infinite;
        }

        .ani-wpp {
          animation: colorChangeWhatsApp 5s infinite;
        }

        .ani-gmail {
          animation: colorChangeGmail 5s infinite;
        }

        span {
          font-size: 0.8rem;
          font-weight: 300;
          margin-top: 5px;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
  
      label {
        font-size: 1rem;
        font-weight: 300;
        margin-bottom: 5px;
      }
  
      input {
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 5px;
        border: none;
        outline: none;
      }
  
      button {
        align-self: flex-end;
        background-color: transparent;
        color: #FFFFFF;
        border: none;
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center; /* Asegura la alineación vertical */
        gap: 10px;
        transition: all 0.3s ease;
  
        .arrow {
          font-size: 2.5rem; /* Aumenta el tamaño de la flecha */
          line-height: 1; /* Ajusta la altura de la flecha */
          position: relative;
          top: -0.1em; /* Ajuste fino para alinear la flecha con el texto */
          transition: transform 0.3s ease;
        }
  
        &:hover {
          transform: translateX(5px);
  
          .arrow {
            transform: translateX(5px);
          }
        }
      }
    }
}